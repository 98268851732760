import '../style/index.scss';
import Helmet from 'react-helmet';
import Header from '../components/Header';
import Form from '../components/Form';
import Tabs from '../components/Tabs';
import Deck from '../components/Deck';
import Label from '../components/Label';
import Card from '../components/Card';
import DocCard from '../components/DocCard';
import { gql, useQuery } from '@apollo/client';
import { useMemo, useState } from 'preact/hooks';
import getSite from '../util/getSite';
import camelToTitle from '../util/camelToTitle';
import Thanks from '../components/Thanks';

export default function Index () {
	const [cart, setCart] = useState([])
		, [tab, setTab] = useState(null)
		, [cover, setCover] = useState({})
		, [finished, setFinished] = useState(false);

	const { data } = useQuery(gql`
        query GetItems ($site: String!) {
            entries (
                section: "benefitSchemes"
                site: [$site]
            ) {
                id
                title
                typeHandle
                ...on benefitSchemes_benefitSchemes_Entry {
                    document (limit: 1) {
                        id
                        url
                    }
                    provider {
                        ...Provider
                    }
                    schemeOptions {
                        title
                        description
                        cost
                        coverLevel
                    }
                }
                ...on benefitSchemes_employeeDefinable_Entry {
                    provider {
                        ...Provider
                    }
                    body
                }
	            ... on benefitSchemes_holiday_Entry {
		            provider {
			            ...Provider
		            }
		            body
	            }
            }
            globalSet (
                handle: "companySettingsBenefits"
                site: [$site]
            ) {
                ... on companySettingsBenefits_GlobalSet {
	                budget
	                holidayPurchaseMaximum
                }
            }
        }
        fragment Provider on providers_providers_Entry {
            id
            title
            logo @transform (
                width: 100
                height: 60
                immediately: true
                mode: "fit"
            ) {
                id
                url
                srcset(sizes: ["1x", "2x"])
            }
        }
	`, {
		variables: {
			site: getSite(),
		},
	});

	const { tabs, cards, coverLevels } = useMemo(() => {
		if (!data) return {};

		const tabs = []
			, cards = {}
			, coverLevels = {};

		data.entries.forEach(entry => {
			if (entry.typeHandle === 'employeeDefinable' || entry.typeHandle === 'holiday') {
				tabs.push({
					id: entry.id,
					title: entry.title,
					provider: entry.provider[0] || null,
				});

				coverLevels[entry.id] = [];
				setCover(c => ({
					...c,
					[entry.id]: '',
				}));

				cards[entry.id] = {'': [{
					id: Math.random().toString(36).substring(7),
					type: entry.typeHandle,
					title: entry.title,
					body: entry.body,
				}]};
				return;
			}

			tabs.push({
				id: entry.id,
				title: entry.title,
				document: entry.document[0] || null,
				provider: entry.provider[0] || null,
			});

			coverLevels[entry.id] = entry.schemeOptions.reduce((a, b) => {
				if (b.coverLevel && a.indexOf(b.coverLevel) === -1)
					a.push(b.coverLevel);

				return a;
			}, []);

			setCover(c => ({
				...c,
				[entry.id]: coverLevels[entry.id][0] || '',
			}));

			cards[entry.id] = entry.schemeOptions.reduce((a, b) => {
				if (!a.hasOwnProperty(b.coverLevel))
					a[b.coverLevel] = [];

				a[b.coverLevel].push({
					...b,
					id: Math.random().toString(36).substring(7),
					type: entry.typeHandle,
				});
				return a;
			}, {});
		});

		setTab(tabs[0]);

		return { tabs, cards, coverLevels };
	}, [data, setTab, setCover]);

	if (!data || !tab)
		return null;

	const onCoverChange = e => {
		setCover(c => ({
			...c,
			[tab.id]: e.target.value,
		}));
	};

	const addToCart = item => {
		setCart(items => [...items, item]);
	};

	const removeFromCart = item => {
		setCart(items => {
			const next = [...items];
			next.splice(items.indexOf(item), 1)
			return next;
		});
	};

	const isInCart = card => {
		for (let i = 0, l = cart.length; i < l; i++)
			if (cart[i].id === card.id)
				return true;

		return false;
	};

	const remainingBudget = data.globalSet.budget - cart.reduce((a, b) => {
		a += b.cost;
		return a;
	}, 0);

	return (
		<>
			<Helmet>
				<title>Employee Benefits - Shackleton Engage</title>
			</Helmet>
			<Header />
			{finished ? (
				<Thanks name={finished} />
			) : (
				<>
					<Form
						cart={cart}
						removeFromCart={removeFromCart}
						setFinished={setFinished}
					/>
					<Tabs tabs={tabs} setTab={setTab} activeTab={tab} />
					<Deck>
						{coverLevels[tab.id].length > 0 && (
							<Label label={`${tab.title} Cover Level`} inline spinner>
								<select onChange={onCoverChange}>
									{coverLevels[tab.id].map(level => (
										<option
											key={level}
											value={level}
											selected={level === cover[tab.id]}
										>
											{camelToTitle(level)}
										</option>
									))}
								</select>
							</Label>
						)}

						{cards[tab.id][cover[tab.id]].map((card, i) => (
							<Card
								key={`${tab.id}_${i}`}
								card={card}
								addToCart={addToCart}
								inCart={isInCart(card)}
								tab={tab}
								remainingBudget={remainingBudget}
								holidayMax={data.globalSet.holidayPurchaseMaximum}
							/>
						))}

						<DocCard
							title={tab.title}
							cover={cover[tab.id]}
							document={tab.document}
						/>
					</Deck>
				</>
			)}
		</>
	);
}
