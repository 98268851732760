import css from './style.module.scss';
import Logo from '../../assets/svg/logo.svg';
import { Helmet } from 'react-helmet';

export default function AuthLayout ({ title, children, onSubmit }) {
	return (
		<div className={css.authLayout}>
			<Helmet>
				<title>{title} - Shackleton Engage</title>
			</Helmet>
			<form onSubmit={onSubmit}>
				<header>
					<Logo />
				</header>

				{children}
			</form>
		</div>
	);
}
